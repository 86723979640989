var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.b(null, { error: _vm.isShakeError && _vm.hasError, group: _vm.group, disabled: _vm.disabled })},[(!!_vm.label)?_c('label',{class:_vm.b('label'),attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:_vm.b('block', _vm.classWrap)},[_c('div',{class:_vm.b('wrap')},[_c('VueTimepicker',_vm._g({class:_vm.b('input', {
            suffix: _vm.$slots.suffix,
            prefix: _vm.$slots.prefix,
            error: _vm.hasError,
          }),attrs:{"id":_vm.id,"format":"HH:mm","hide-clear-button":"","minute-interval":30,"value":_vm.value,"hour-label":"часы","minute-label":"минуты","disabled":_vm.disabled}},Object.assign({}, _vm.$listeners,
          {input: function (event) { return _vm.$emit('input', event); },
          change: function (event) { return _vm.$emit('change', event); },
          focus: function (event) { return _vm.$emit('focus', event); },
          blur: function (event) { return _vm.$emit('blur', event); }})))],1),_vm._t("default"),_c('transition',{attrs:{"name":"fade"}},[(_vm.hasError)?_c('div',{class:_vm.b('error')},[_vm._v(" "+_vm._s(_vm.errorText)+" ")]):_vm._e()])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }